var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "d-flex flex-column afastados-e-inativos-card",
      attrs: { elevation: "0" },
    },
    [
      _c("FilterEncapsulated", {
        ref: "filter",
        attrs: {
          switches: _vm.filterSwitches,
          disabled: _vm.isFiltersDisabled,
        },
        on: {
          applyFilter: _vm.applyFilter,
          clearFilters: _vm.clearFilter,
          toggleSwitch: _vm.toggleSwitchFilter,
          closeFilterDialog: _vm.closeFilterDialog,
        },
        scopedSlots: _vm._u([
          {
            key: "filters",
            fn: function () {
              return [
                _c("SelectMultiple", {
                  attrs: {
                    id: "selectCnpj",
                    label: _vm.txt.cnpj,
                    items: _vm.selectCnpj,
                    disabled: _vm.isFiltersDisabled,
                    hasSelectAll: true,
                  },
                  model: {
                    value: _vm.filters.cnpj,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters, "cnpj", $$v)
                    },
                    expression: "filters.cnpj",
                  },
                }),
                _c("AGDatePickerRange", {
                  attrs: {
                    id: "datepickerPeriod",
                    label: _vm.txt.period,
                    disabled: _vm.isFiltersDisabled,
                  },
                  model: {
                    value: _vm.filters.period,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters, "period", $$v)
                    },
                    expression: "filters.period",
                  },
                }),
                _c("SelectMultiple", {
                  attrs: {
                    id: "selectRegimePrevidenciario",
                    label: _vm.txt.regimePrevidenciario,
                    items: _vm.selectRegimePrevidenciario,
                    disabled: _vm.isFiltersDisabled,
                    hasSelectAll: true,
                  },
                  model: {
                    value: _vm.filters.regimePrevidenciario,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters, "regimePrevidenciario", $$v)
                    },
                    expression: "filters.regimePrevidenciario",
                  },
                }),
                _c("SelectMultiple", {
                  attrs: {
                    id: "selectCategory",
                    label: _vm.txt.category,
                    items: _vm.selectCategory,
                    disabled: _vm.isFiltersDisabled,
                    hasSelectAll: true,
                  },
                  model: {
                    value: _vm.filters.category,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters, "category", $$v)
                    },
                    expression: "filters.category",
                  },
                }),
                _c("SelectMultiple", {
                  attrs: {
                    id: "selectMotivoAfastamento",
                    label: _vm.txt.motivoDeAfastamento,
                    items: _vm.selectMotivoDeAfastamento,
                    disabled: _vm.isFiltersDisabled,
                    hasSelectAll: true,
                  },
                  model: {
                    value: _vm.filters.motivoDeAfastamento,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters, "motivoDeAfastamento", $$v)
                    },
                    expression: "filters.motivoDeAfastamento",
                  },
                }),
                _c("SelectMultiple", {
                  attrs: {
                    id: "selectRole",
                    label: _vm.txt.role,
                    items: _vm.selectRole,
                    disabled: _vm.isFiltersDisabled,
                    hasSelectAll: true,
                  },
                  model: {
                    value: _vm.filters.role,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters, "role", $$v)
                    },
                    expression: "filters.role",
                  },
                }),
                _c("SelectMultiple", {
                  attrs: {
                    id: "selectCbo",
                    label: _vm.txt.cbo,
                    items: _vm.selectCbo,
                    disabled: _vm.isFiltersDisabled,
                    hasSelectAll: true,
                  },
                  model: {
                    value: _vm.filters.cbo,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters, "cbo", $$v)
                    },
                    expression: "filters.cbo",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("GraphsContainer", {
        attrs: {
          title: _vm.txt.graphsContainerTitle,
          disabled: _vm.loadingGraphs,
        },
        scopedSlots: _vm._u([
          {
            key: "graphs",
            fn: function () {
              return [
                _c(
                  "v-card",
                  { staticClass: "tabs-afastados-e-inativos" },
                  [
                    _c(
                      "v-card-title",
                      [
                        _c(
                          "v-tabs",
                          {
                            model: {
                              value: _vm.tabSelected,
                              callback: function ($$v) {
                                _vm.tabSelected = $$v
                              },
                              expression: "tabSelected",
                            },
                          },
                          [
                            _c("v-tabs-slider"),
                            _c(
                              "v-tab",
                              {
                                attrs: {
                                  value: "total-afastamento",
                                  id: "total-afastamentos",
                                },
                              },
                              [
                                _c("div", { staticClass: "tab-text" }, [
                                  _vm._v(_vm._s(_vm.txt.totalDeAfastamentos)),
                                ]),
                              ]
                            ),
                            _c(
                              "v-tab",
                              {
                                attrs: {
                                  value: "afastamentos-por-categoria",
                                  id: "afastamentos-por-categoria",
                                },
                              },
                              [
                                _c("div", { staticClass: "tab-text" }, [
                                  _vm._v(
                                    _vm._s(_vm.txt.afastamentoPorCategoria)
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "v-tab",
                              {
                                attrs: {
                                  value: "motivos-afastamento",
                                  id: "cedidos",
                                },
                              },
                              [
                                _c("div", { staticClass: "tab-text" }, [
                                  _vm._v(_vm._s(_vm.txt.motivoDeAfastamento)),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-tabs-items",
                      {
                        model: {
                          value: _vm.tabSelected,
                          callback: function ($$v) {
                            _vm.tabSelected = $$v
                          },
                          expression: "tabSelected",
                        },
                      },
                      [
                        _c(
                          "v-tab-item",
                          [
                            _vm.loadingGraphs
                              ? _c("v-skeleton-loader", {
                                  attrs: { type: "list-item-two-line" },
                                })
                              : _c("GraphLinePercentual", {
                                  staticClass: "ma-4",
                                  attrs: {
                                    values: _vm.totalDeAfastamentosGraph,
                                    showLegend: true,
                                  },
                                }),
                          ],
                          1
                        ),
                        _c(
                          "v-tab-item",
                          [
                            _vm.loadingGraphs
                              ? [
                                  _vm.loadingGraphs
                                    ? _c("v-skeleton-loader", {
                                        attrs: { type: "list-item-two-line" },
                                      })
                                    : _vm._e(),
                                ]
                              : [
                                  _c(
                                    "div",
                                    { staticClass: "icon-helper-container" },
                                    [
                                      _c("IconTooltip", {
                                        attrs: {
                                          icon: "help",
                                          iconClass:
                                            "material-symbols-outlined",
                                          tooltipText:
                                            _vm.txt
                                              .helperAfastamentoPorCategoriaTooltipText,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._l(
                                    _vm.afastamentosPorCategoriasGraphs,
                                    function (graph, index) {
                                      return [
                                        _c("GraphLinePercentual", {
                                          staticClass: "ma-4",
                                          attrs: {
                                            values: graph,
                                            title: graph.title,
                                          },
                                        }),
                                        !(
                                          index ===
                                          _vm.afastamentosPorCategoriasGraphs
                                            .length -
                                            1
                                        )
                                          ? _c("v-divider", {
                                              staticClass: "graphs-divider",
                                            })
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                ],
                          ],
                          2
                        ),
                        _c(
                          "v-tab-item",
                          [
                            _vm.loadingGraphs
                              ? [
                                  _vm.loadingGraphs
                                    ? _c("v-skeleton-loader", {
                                        attrs: { type: "list-item-two-line" },
                                      })
                                    : _vm._e(),
                                ]
                              : [
                                  _c(
                                    "div",
                                    { staticClass: "icon-helper-container" },
                                    [
                                      _c(
                                        "IconTooltip",
                                        {
                                          attrs: {
                                            icon: "help",
                                            iconClass:
                                              "material-symbols-outlined",
                                            largeTooltip: true,
                                            largeTooltipTitle:
                                              _vm.txt
                                                .helperPorMotivosDeAfastamentoTooltipTitle,
                                            largeTooltipIcon: "help",
                                            largeTooltipIconClass:
                                              "material-symbols-outlined",
                                          },
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.txt
                                                    .helperPorMotivosDeAfastamentoTooltipText1
                                                ) +
                                                " "
                                            ),
                                            _c("br"),
                                            _c("br"),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.txt
                                                    .helperPorMotivosDeAfastamentoTooltipText2
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._l(
                                    _vm.motivosDeAfastamentoGraphs,
                                    function (graph, index) {
                                      return [
                                        _c("GraphLinePercentual", {
                                          staticClass: "ma-4",
                                          attrs: {
                                            values: graph,
                                            title: graph.title,
                                          },
                                        }),
                                        !(
                                          index ===
                                          _vm.motivosDeAfastamentoGraphs
                                            .length -
                                            1
                                        )
                                          ? _c("v-divider", {
                                              staticClass: "graphs-divider",
                                            })
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                ],
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        [
          _c(
            "v-card",
            { staticClass: "card-table-afastados-e-inativos" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-row justify-space-between card-table-afastados-e-inativos-header",
                },
                [
                  _c("TableSearch", {
                    attrs: {
                      label: _vm.txt.tableSearchLabel,
                      disabled: _vm.loadingTable,
                    },
                    on: { input: _vm.onInputSearch },
                    model: {
                      value: _vm.tableAfastadosSearch,
                      callback: function ($$v) {
                        _vm.tableAfastadosSearch = $$v
                      },
                      expression: "tableAfastadosSearch",
                    },
                  }),
                  _c(
                    "div",
                    [
                      _c("ExportButton", {
                        attrs: { disabled: _vm.loadingTable },
                        on: { click: _vm.onExportTableAfastados },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("TableAfastadosEInativos", {
                attrs: {
                  loadingTable: _vm.loadingTable,
                  tableTitle: _vm.txt.tableTitle,
                  headers: _vm.tableAfastadosEInativosHeader,
                  items: _vm.colaboradoresList,
                  paginationFilter: _vm.colaboradoresPagination,
                  totalItems: _vm.totalTableCollaborators,
                },
                on: { setPagination: _vm.setPagination },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <v-card class="d-flex flex-column afastados-e-inativos-card" elevation="0">
    <FilterEncapsulated
      ref="filter"
      :switches="filterSwitches"
      :disabled="isFiltersDisabled"
      @applyFilter="applyFilter"
      @clearFilters="clearFilter"
      @toggleSwitch="toggleSwitchFilter"
      @closeFilterDialog="closeFilterDialog"
    >
      <template v-slot:filters>
        <SelectMultiple
          id="selectCnpj"
          :label="txt.cnpj"
          v-model="filters.cnpj"
          :items="selectCnpj"
          :disabled="isFiltersDisabled"
          :hasSelectAll="true"
        />
        <AGDatePickerRange
          id="datepickerPeriod"
          :label="txt.period"
          v-model="filters.period"
          :disabled="isFiltersDisabled"
        />
        <SelectMultiple
          id="selectRegimePrevidenciario"
          :label="txt.regimePrevidenciario"
          v-model="filters.regimePrevidenciario"
          :items="selectRegimePrevidenciario"
          :disabled="isFiltersDisabled"
          :hasSelectAll="true"
        />
        <SelectMultiple
          id="selectCategory"
          :label="txt.category"
          v-model="filters.category"
          :items="selectCategory"
          :disabled="isFiltersDisabled"
          :hasSelectAll="true"
        />
        <SelectMultiple
          id="selectMotivoAfastamento"
          :label="txt.motivoDeAfastamento"
          v-model="filters.motivoDeAfastamento"
          :items="selectMotivoDeAfastamento"
          :disabled="isFiltersDisabled"
          :hasSelectAll="true"
        />
        <SelectMultiple
          id="selectRole"
          :label="txt.role"
          v-model="filters.role"
          :items="selectRole"
          :disabled="isFiltersDisabled"
          :hasSelectAll="true"
        />
        <SelectMultiple
          id="selectCbo"
          :label="txt.cbo"
          v-model="filters.cbo"
          :items="selectCbo"
          :disabled="isFiltersDisabled"
          :hasSelectAll="true"
        />
      </template>
    </FilterEncapsulated>
    <GraphsContainer :title="txt.graphsContainerTitle" :disabled="loadingGraphs">
      <template v-slot:graphs>
        <v-card class="tabs-afastados-e-inativos">
          <v-card-title>
            <v-tabs v-model="tabSelected">
              <v-tabs-slider></v-tabs-slider>
              <v-tab value="total-afastamento" id="total-afastamentos">
                <div class="tab-text">{{ txt.totalDeAfastamentos }}</div>
              </v-tab>
              <v-tab value="afastamentos-por-categoria" id="afastamentos-por-categoria">
                <div class="tab-text">{{ txt.afastamentoPorCategoria }}</div>
              </v-tab>
              <v-tab value="motivos-afastamento" id="cedidos">
                <div class="tab-text">{{ txt.motivoDeAfastamento }}</div>
              </v-tab>
            </v-tabs>
          </v-card-title>
          <v-tabs-items v-model="tabSelected">
            <v-tab-item>
              <v-skeleton-loader v-if="loadingGraphs" type="list-item-two-line"></v-skeleton-loader>
              <GraphLinePercentual v-else :values="totalDeAfastamentosGraph" class="ma-4" :showLegend="true"/>
            </v-tab-item>
            <v-tab-item>
              <template v-if="loadingGraphs">
                <v-skeleton-loader v-if="loadingGraphs" type="list-item-two-line"></v-skeleton-loader>
              </template>
              <template v-else>
                <div class="icon-helper-container">
                  <IconTooltip
                    icon="help"
                    iconClass="material-symbols-outlined"
                    :tooltipText="txt.helperAfastamentoPorCategoriaTooltipText"
                  ></IconTooltip>
                </div>
                <template v-for="(graph, index) in afastamentosPorCategoriasGraphs">
                  <GraphLinePercentual :values="graph" :title="graph.title" class="ma-4" />
                  <v-divider v-if="!(index === afastamentosPorCategoriasGraphs.length - 1)" class="graphs-divider" />
                </template>
              </template>
            </v-tab-item>
            <v-tab-item>
              <template v-if="loadingGraphs">
                <v-skeleton-loader v-if="loadingGraphs" type="list-item-two-line"></v-skeleton-loader>
              </template>
              <template v-else>
                <div class="icon-helper-container">
                  <IconTooltip
                    icon="help"
                    iconClass="material-symbols-outlined"
                    :largeTooltip="true"
                    :largeTooltipTitle="txt.helperPorMotivosDeAfastamentoTooltipTitle"
                    largeTooltipIcon="help"
                    largeTooltipIconClass="material-symbols-outlined"
                  >
                    <div>
                      {{ txt.helperPorMotivosDeAfastamentoTooltipText1 }}
                      <br /><br />
                      {{ txt.helperPorMotivosDeAfastamentoTooltipText2 }}
                    </div>
                  </IconTooltip>
                </div>
                <template v-for="(graph, index) in motivosDeAfastamentoGraphs">
                  <GraphLinePercentual :values="graph" :title="graph.title" class="ma-4"/>
                  <v-divider v-if="!(index === motivosDeAfastamentoGraphs.length - 1)" class="graphs-divider" />
                </template>
              </template>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </template>
    </GraphsContainer>
    <div>
      <v-card class="card-table-afastados-e-inativos">
        <div class="d-flex flex-row justify-space-between card-table-afastados-e-inativos-header">
          <TableSearch
            :label="txt.tableSearchLabel"
            v-model="tableAfastadosSearch"
            @input="onInputSearch"
            :disabled="loadingTable"
          ></TableSearch>
          <div>
            <ExportButton @click="onExportTableAfastados" :disabled="loadingTable"></ExportButton>
          </div>
        </div>
        <TableAfastadosEInativos
          :loadingTable="loadingTable"
          :tableTitle="txt.tableTitle"
          :headers="tableAfastadosEInativosHeader"
          :items="colaboradoresList"
          :paginationFilter="colaboradoresPagination"
          :totalItems="totalTableCollaborators"
          @setPagination="setPagination"
        >
        </TableAfastadosEInativos>
      </v-card>
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import { eventBus } from '@/main.js';

export default {
  name: 'AfastadosEInativos',

  components: {
    FilterEncapsulated: () => import('../commons/FilterEncapsulated.vue'),
    SelectMultiple: () => import('../commons/SelectMultiple.vue'),
    AGDatePickerRange: () => import('../commons/AGDatePickerRange.vue'),
    GraphsContainer: () => import('../commons/GraphsContainer.vue'),
    GraphLinePercentual: () => import('../commons/GraphLinePercentual.vue'),
    IconTooltip: () => import('../commons/IconTooltip.vue'),
    TableAfastadosEInativos: () => import('./components/TableAfastadosEInativos.vue'),
    TableSearch: () => import('../commons/TableSearch.vue'),
    ExportButton: () => import('../commons/ExportButton.vue'),
  },
  data() {
    return {
      filterSwitches: [
        {
          id: 'switch_afasados',
          label: 'Afastados',
          value: false,
          disabled: true,
        },
        {
          id: 'switch_inativos',
          label: 'Inativos',
          value: false,
          disabled: true,
        },
      ],
      filters: {
        afastados: false,
        inativos: false,
        cnpj: [],
        period: [],
        regimePrevidenciario: [],
        category: [],
        motivoDeAfastamento: [],
        role: [],
        cbo: [],
      },
      tabSelected: 0,
      txt: {
        cnpj: 'CNPJ',
        period: 'Período',
        regimePrevidenciario: 'Regime previdenciário',
        category: 'Categoria',
        motivoDeAfastamento: 'Motivo de afastamento',
        role: 'Cargo',
        cbo: 'CBO',
        graphsContainerTitle: '<strong>Gráficos</strong> por categoria e motivo de afastamento',
        totalDeAfastamentos: 'Total de afastamentos',
        afastamentoPorCategoria: 'Afastamentos por categoria',
        porMotivosDeAfastamento: 'Por motivos de afastamento',
        helperPorMotivosDeAfastamentoTooltipTitle: 'Ajuda',
        helperAfastamentoPorCategoriaTooltipText:
          'Este gráfico pode receber filtros por diferentes critérios, incluindo: CNPJ, período, regime previdenciário, motivo de afastamento, cargo e CBO.',
        helperPorMotivosDeAfastamentoTooltipText1:
          'Gozo de férias ou recesso: Afastamento temporário para desfrutar de férias ou recesso e Licença remunerada não são consideradas como afastamento nesta seção. A inatividade de um funcionário será considerada apenas nos casos aposentadoria por invalidez e inatividade do trabalhador avulso (portuário ou não portuário) por mais de 90 dias. Para todas as outras situações, o empregado será considerado ativo.',
        helperPorMotivosDeAfastamentoTooltipText2:
          'Este gráfico pode ser filtrado por diferentes critérios, incluindo: CNPJ, período, regime previdenciário, categoria, cargo e CBO.',
        tableSearchLabel: 'Pesquisar por CNPJ, matrícula, CPF ou motivo de afastamento',
        tableTitle: 'Afastados',
      },
      tableAfastadosSearch: '',
      tableAfastadosEInativosHeader: [
        { text: '', value: 'id_column', width: '100%', sortable: false },
        { text: 'Empresa', value: 'nomeEmpresa', width: '100%', sortable: true },
        { text: 'CPF', value: 'cpf', width: '150px', sortable: true },
        { text: 'Matrícula', value: 'matricula', width: '100%', sortable: true },
        { text: 'Motivo de afastamento', value: 'motivoAfastamento', width: '320px', sortable: true },
        { text: 'Inicio do afastamento', value: 'inicioAfastamento', width: '175px', sortable: false },
        { text: 'Fim do afastamento', value: 'fimAfastamento', width: '175px', sortable: false },
        { text: 'Grupo de categoria', value: 'grupoCategoria', width: '350px', sortable: true },
        { text: 'Categoria', value: 'categoria', width: '100%', sortable: true },
        { text: 'Regime Previdenciário', value: 'tpRegPrev', width: '100px', sortable: true },
        { text: 'CBO', value: 'cbo', width: '100%', sortable: true },
        { text: 'Cargo', value: 'cargo', width: '320px', sortable: true },
        { text: 'Situação', value: 'situacao', width: '100px', sortable: true },
      ],
      toast: {
        message: '',
        type: '',
        show: false,
        topToast: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      loadingGraphs: 'esocial/esocialAfastadosEInativos/getLoadingGraphs',
      filtersSaved: 'esocial/esocialAfastadosEInativos/getFiltersSaved',
      totalDeAfastamentosGraph: 'esocial/esocialAfastadosEInativos/getTotalAfastamentoGraph',
      afastamentosPorCategoriasGraphs: 'esocial/esocialAfastadosEInativos/getAfastamentosPorCategoriaGraphs',
      motivosDeAfastamentoGraphs: 'esocial/esocialAfastadosEInativos/getMotivosDeAfastamentoGraphs',
      colaboradoresList: 'esocial/esocialAfastadosEInativos/getColaboradoresList',
      colaboradoresPagination: 'esocial/esocialAfastadosEInativos/getColaboradoresPagination',
      loadingColaboradoresList: 'esocial/esocialAfastadosEInativos/getLoadingColaboradoresList',
      selectCnpj: 'esocial/esocialAfastadosEInativos/getSelectCnpj',
      selectRegimePrevidenciario: 'esocial/esocialAfastadosEInativos/getSelectRegimePrevidenciario',
      selectCategory: 'esocial/esocialAfastadosEInativos/getSelectCategory',
      selectMotivoDeAfastamento: 'esocial/esocialAfastadosEInativos/getSelectMotivoDeAfastamento',
      selectRole: 'esocial/esocialAfastadosEInativos/getSelectRole',
      selectCbo: 'esocial/esocialAfastadosEInativos/getSelectCbo',
      loadingCnpjFilter: 'esocial/esocialAfastadosEInativos/getLoadingCnpjFilter',
      loadingFilters: 'esocial/esocialAfastadosEInativos/getLoadingFilters',
      scheduleReport: 'esocial/esocialAfastadosEInativos/getScheduleReport',
      totalTableCollaborators: 'esocial/esocialAfastadosEInativos/getTotalColaboradoresList',
    }),
    loadingAllFilters() {
      return this.loadingCnpjFilter || this.loadingFilters;
    },
    isFiltersDisabled() {
      return this.loadingGraphs || this.loadingAllFilters;
    },
    loadingTable() {
      return this.loadingColaboradoresList;
    },
  },
  created() {
    this.fetchSelectCnpj();
    this.fetchFilterSelectsData();
    this.fetchDataColaboradoresList('');
    this.fetchDataGraphs();
  },
  methods: {
    ...mapActions('esocial/esocialAfastadosEInativos', [
      'clearFilters',
      'applyFilters',
      'fetchDataGraphs',
      'fetchDataColaboradoresList',
      'fetchSelectCnpj',
      'fetchFilterSelectsData',
      'setColaboradoresPagination',
      'exportTableAfastados',
    ]),
    clearFilter() {
      this.clearFilters({ filterTableTerm: this.tableAfastadosSearch });
      this.toggleOffSwitches();
    },
    applyFilter() {
      this.applyFilters({
        filters: this.filters,
        filterTableTerm: this.tableAfastadosSearch,
      });
    },
    toggleSwitchFilter(item) {
      switch (item.id) {
        case 'switch_afasados':
          this.filterSwitches[1].value = false;
          this.filters.inativos = false;
          this.filters.afastados = item.value;
          break;
        case 'switch_inativos':
          this.filterSwitches[0].value = false;
          this.filters.afastados = false;
          this.filters.inativos = item.value;
          break;
        default:
          console.error('No case found for toggling filter switches');
      }
      this.applyFilter();
    },
    toggleOffSwitches() {
      this.filterSwitches.forEach((item) => {
        item.value = false;
      });
    },
    closeFilterDialog() {
      this.filters = cloneDeep(this.filtersSaved);
    },
    async onExportTableAfastados() {
      await this.exportTableAfastados();
      if (this.scheduleReport) {
        this.showToast(
          'Recebemos seu pedido com sucesso! Nos menus Agnes em: Documentos e Download. Assim que concluído, o arquivo estará disponível.',
          'success',
          78,
        );
      } else {
        this.showToast('Erro ao exportar relatório.', 'error', 78);
      }
    },
    showToast(message, type, topToast) {
      this.toast.show = true;
      this.toast.type = type;
      this.toast.message = message;
      this.toast.topToast = topToast;
      eventBus.$emit('openToast', this.toast);
    },
    onInputSearch(val) {
      this.fetchDataColaboradoresList(val);
    },
    setPagination(pagination) {
      this.setColaboradoresPagination({ pagination: pagination, term: this.tableAfastadosSearch });
    },
  },
  watch: {
    isFiltersDisabled(newVal, oldVal) {
      this.filterSwitches.forEach((item) => {
        item.disabled = newVal;
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import './src/design/variables.scss';

.filters-dropdown-content {
  div {
    padding-top: $spacing-05;
  }
}

.afastados-e-inativos-card {
  border-top-width: 0;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

::v-deep .v-slide-group__content.v-tabs-bar__content {
  background: none;
}

.tabs-afastados-e-inativos {
  padding: 0 !important;
  .v-card__title {
    padding: 0;
    margin: 0;
  }
  ::v-deep .v-window__container {
    padding: $spacing-04;
  }
  .graphs-divider {
    margin: $spacing-03;
  }
}

.icon-helper-container {
  display: flex;
  flex-direction: row-reverse;
  margin-right: $spacing-03;
}

.card-table-afastados-e-inativos {
  margin-top: $spacing-05;
  padding: 0;
  .card-table-afastados-e-inativos-header {
    margin: $spacing-05;
  }
}
</style>
